import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AccessTokenDto, Role } from "../services/auth/auth.dto";
import { useToast } from "./toast";

interface User {
  id: string;
  email: string;
  role: string;
  username: string;
  firstName: "";
  lastName: "";
  permissions: string[];
  exp: Date;
  token: string;
}

interface AuthContextModel {
  getUser: () => User;
  setToken: (input: AccessTokenDto, remember: boolean) => void;
  removeUserFromStorage: () => void;
}

let AuthContext = React.createContext<AuthContextModel>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  var user: User = {
    id: "",
    email: "",
    role: "",
    username: "",
    firstName: "",
    lastName: "",
    permissions: [],
    exp: new Date(),
    token: ""
  };

  const setUserFromStorage = () => {
    user.token = localStorage.getItem("token") || "";

    if (user.token.length === 0) {
      user.token = sessionStorage.getItem("token") || "";
    }

    if (user.token.length > 0) {
      const decoded = jwtDecode(user.token) as any;

      user.id = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

      user.username = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];

      user.id = decoded["id"]; // id'yi doğru şekilde almak
      user.email = decoded["email"]; // email'yi doğru şekilde almak
      //user.username = decoded["username"]; // username'yi doğru şekilde almak
      //user.role = decoded["role"];
      const role = decoded["role"];
      if (role === "Admin") {
        user.role = Role.Admin;
      } else if (role === "OsbukUser") {
        user.role = Role.OsbukUser;
      } else {
        user.role = Role.User;
      }
      user.firstName = decoded["first_name"];
      user.lastName = decoded["last_name"];
      user.exp = new Date(decoded["exp"] * 1000);

      //   let rawPermissions = decoded["Permissions"];
      //   if (rawPermissions) {
      //     user.permissions = rawPermissions;
      //   }
    } else {
      user = {
        id: "",
        email: "",
        role: "",
        username: "",
        firstName: "",
        lastName: "",
        permissions: [],
        exp: new Date(),
        token: ""
      };
    }

    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  };

  const removeUserFromStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("role");
    setUserFromStorage();
  };

  const setToken = (input: AccessTokenDto, remember: boolean) => {
    if (input.token) {
      localStorage.setItem("token", input.token);
    }

    setUserFromStorage();
  };

  const getUser = () => {
    setUserFromStorage();
    return user;
  };

  const value = { getUser, setToken, removeUserFromStorage };

  setUserFromStorage();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function HasPermission({ role, children }: { role: Role; children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  var user = auth.getUser();
  const toast = useToast();

  if (user.token.length === 0) {
    toast.show("Öncelikle giriş yapmalısınız.", "error");
    return <Navigate to="/loginsebis" state={{ from: location }} replace />;
  }
  if (user.role === Role.Admin || user.role === role || (role === Role.User && user.role === Role.OsbukUser)) {
    return children;
  } else {
    toast.show("Bu sayfa için erişim yetkiniz yok.", "error");
    return <Navigate to={"/access-denied"} replace={true} />;
  }

  //   if (permissions && permissions.length > 0) {
  //     for (let permission of permissions) {
  //       if (!user.permissions.includes(permission)) {
  //         return <Navigate to={"/access-denied"} replace={false} />;
  //       }
  //     }
  //   } else {
  //     return children;
  //   }

  return children;
}
