import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import accountService from "../../services/account/account.service";
import { useAuth } from "../../utils/auth";
import { UpdateProfileDto } from "../../services/account/account.dto";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { useToast } from "../../utils/toast";
import { InputMask } from "primereact/inputmask";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DropdownDatas from "../../utils/DropdownValues";
import { t } from "i18next";
import { AutoComplete, AutoCompleteChangeEvent } from "primereact/autocomplete";

const ProfilePage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const user = useAuth();

  useEffect(() => {
    accountService.GetUserInformation().then(res => {
      if (res && res.result?.data) {
        formik.setFieldValue("first_name", res.result.data.first_name);
        formik.setFieldValue("last_name", res.result.data.last_name);
        formik.setFieldValue("email", res.result.data.email);
        formik.setFieldValue("phone_number", res.result.data.phone_number);
        formik.setFieldValue("osb", res.result.data.osb);
        setSelectedOSB(res.result.data.osb);
        formik.setFieldValue("osb_gorevi", res.result.data.osb_gorevi);
      }
    });
  }, [user]);

  const containerClassName = classNames("surface-ground flex align-items-center justify-content-center pt-5");

  const formik = useFormik<UpdateProfileDto>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      osb: "",
      osb_gorevi: ""
    },
    enableReinitialize: true,
    validate: data => {
      const errors: any = {};
      if (!data.first_name) {
        errors.first_name = "İsim gereklidir";
      }

      if (!data.last_name) {
        errors.last_name = "Soyad gereklidir";
      }

      if (!data.email) {
        errors.email = "Email gereklidir";
      }

      if (!data.phone_number) {
        errors.phone_number = "Telefon numarası gereklidir";
      }

      if (!data.osb) {
        errors.osb = "OSB gereklidir";
      }

      if (!data.osb_gorevi) {
        errors.osb_gorevi = "OSB görevi gereklidir";
      }

      return errors;
    },
    onSubmit: values => {
      accountService.UpdateProfile(values).then(res => {
        if (!res?.result?.error) {
          toast.show("Profil başarıyla güncellendi", "success");
          //navigate("/");
        } else {
          toast.show(res?.result?.error || "Kayıt işlemi sırasında bir hata oluştu.", "error");
        }
      });
    }
  });

  const [filteredCountries, setFilteredCountries] = useState<any[]>([]); // Filtrelenen listeyi tutar
  const [selectedOSB, setSelectedOSB] = useState<any>(null);
  const allCountries = DropdownDatas.getOSBList(); // Tüm OSB listesi

  const search = (event: { query: string }) => {
    const query = event.query.toLowerCase();
    const filtered = allCountries.filter(item => item.label.toLowerCase().includes(query));
    setFilteredCountries(filtered);
  };

  return (
    <>
      <form
        className="surface-ground flex align-items-center justify-content-center pt-5"
        onSubmit={formik.handleSubmit}
      >
        <div className="flex flex-column align-items-center justify-content-center">
          <div
            style={{
              borderRadius: "56px",
              padding: "0.3rem",
              background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
              maxWidth: "1000px"
            }}
          >
            <div className="w-full surface-card py-5 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
              <div className="text-center mb-5">
                <Link to="/">
                  <img src="/images/logoosbuk.png" alt="Image" height="50" className="mb-3" />
                </Link>
                <div className="text-900 text-3xl font-medium mb-3">Profilimi Güncelle</div>
                <span className="text-600 font-medium">Bu ekrandan profil bilgilerinizi güncelleyebilirsiniz.</span>
              </div>
              <div className="grid">
                <div className="col-12 md:col-6">
                  <label htmlFor="first_name" className="text-primary">
                    {"İsim"}
                  </label>
                  <InputText
                    id="first_name"
                    type="text"
                    placeholder={"İsim"}
                    className={classNames("w-full  mt-2", {
                      "p-invalid": FormikValueIsValid(formik, "first_name")
                    })}
                    value={formik.values.first_name}
                    onChange={e => formik.setFieldValue("first_name", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(formik, "first_name") && (
                    <div className="p-error mt-3">{formik.errors.first_name}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="last_name" className="text-primary">
                    Soy isim
                  </label>
                  <InputText
                    id="last_name"
                    type="text"
                    placeholder="Soy isim"
                    className={classNames("w-full  mt-2", {
                      "p-invalid": FormikValueIsValid(formik, "last_name")
                    })}
                    value={formik.values.last_name}
                    onChange={e => formik.setFieldValue("last_name", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(formik, "last_name") && (
                    <div className="p-error mt-3">{formik.errors.last_name}</div>
                  )}
                </div>

                <div className="col-12 md:col-6">
                  <label htmlFor="email" className="text-primary">
                    {"E-Posta Adresi"}
                  </label>
                  <InputText
                    id="email"
                    type="email"
                    placeholder={"E-Posta Adresi"}
                    className={classNames("w-full")}
                    value={formik.values.email}
                    onChange={e => formik.setFieldValue("email", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(formik, "email") && <div className="p-error mt-3">{formik.errors.email}</div>}
                </div>

                <div className="col-12 md:col-6">
                  <label htmlFor="phone_number" className="text-primary">
                    {"Telefon Numarası"}
                  </label>
                  <InputMask
                    id="phone_number"
                    keyfilter="int"
                    mask="(0999) 999-99-99"
                    placeholder={"Telefon Numarası"}
                    className={classNames("w-full")}
                    value={formik.values.phone_number}
                    onChange={e => formik.setFieldValue("phone_number", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(formik, "phone_number") && (
                    <div className="p-error mt-3">{formik.errors.phone_number}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label className="text-primary" htmlFor="osb">
                    Bağlı Olduğunuz OSB
                  </label>
                  <AutoComplete
                    id="osb"
                    name="osb"
                    inputId="osb"
                    value={selectedOSB}
                    suggestions={filteredCountries}
                    completeMethod={search}
                    field="label"
                    onChange={(e: AutoCompleteChangeEvent) => {
                      setSelectedOSB(e.value);
                      formik.setFieldValue("osb", e.value.value ? e.value.value : e.value);
                    }}
                    placeholder="Bağlı Olduğunuz OSB"
                    dropdown
                    className={classNames("w-full mt-2", {
                      "p-invalid": FormikValueIsValid(formik, "osb")
                    })}
                  />
                  {FormikValueIsValid(formik, "osb") && <div className="p-error mt-3">{formik.errors.osb}</div>}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="osb_gorevi" className="text-primary">
                    OSB'deki Göreviniz
                  </label>
                  <InputText
                    id="osb_gorevi"
                    type="string"
                    placeholder="OSB'deki Göreviniz"
                    className={classNames("w-full mt-2", {
                      "p-invalid": FormikValueIsValid(formik, "osb_gorevi")
                    })}
                    value={formik.values.osb_gorevi}
                    onChange={e => formik.setFieldValue("osb_gorevi", e.target.value)}
                  />
                  {FormikValueIsValid(formik, "osb_gorevi") && (
                    <div className="p-error mt-3">{formik.errors.osb_gorevi}</div>
                  )}
                </div>

                <div className="w-full">
                  <Button label="Güncelle" className="w-full text-xl mt-2" type="submit"></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProfilePage;
