import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import CalistayAnketSirala1 from "../calistay/CalistayAnketSirala1";
import CalistayAnketSirala2 from "../calistay/CalistayAnketSirala2";
import CalistayAnketSirala3 from "../calistay/CalistayAnketSirala3";
import CalistayAnketSirala4 from "../calistay/CalistayAnketSirala4";
import CalistayAnketSirala5 from "../calistay/CalistayAnketSirala5";
import CalistayAnketSirala6 from "../calistay/CalistayAnketSirala6";
import CalistayAnketSirala7 from "../calistay/CalistayAnketSirala7";
import CalistayAnketYorum1 from "../calistay/anket3/CalistayAnketYorum1";
import CalistayAnket41 from "../calistay/anket4/CalistayAnket41";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";

const reports = [
  {
    id: 1,
    title: "Durum Değerlendirmesi Raporu",
    description: "DURUM DEĞERLENDİRMESİ RAPORU V.1.0",
    date: "2024-11-19",
    imageUrl: "/reports/kapakfoto/rapor-kapak.png",
    filePath: "/belgeler/Rapor.pdf"
  },
  {
    id: 2,
    title: "Çalıştay Raporu",
    description: "OSB’LERİN YEŞİL VE DİJİTAL DÖNÜŞÜMÜ İÇİN STRATEJİK PLAN ÇALIŞTAY RAPORU",
    date: "2024-11-20",
    imageUrl: "/belgeler/image.png",
    filePath: "/belgeler/CalistayRapor.pdf"
  },
  {
    id: 3,
    title: "Bilgi Notu",
    description: "OSB’lerin Yeşil ve Dijital Dönüşümü İçin Stratejik Çalışma",
    date: "2024-10-20",
    imageUrl: "/images/background.webp",
    filePath: "/belgeler/BilgiNotu.pdf"
  }
];

interface TumAnketler {
  name: string;
  surveys: Anket[];
}

interface Anket {
  name: string;
  component: JSX.Element;
}

const StratejikPlanCalismasi = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedSurvey, setSelectedSurvey] = useState<JSX.Element | null>(null);

  const groups: TumAnketler[] = [
    {
      name: "Amaç Belirlemek",
      surveys: [
        {
          name: "Yeşil Dönüşüm",
          component: <CalistayAnketSirala1 />
        },
        {
          name: "Dijital Dönüşüm",
          component: <CalistayAnketSirala2 />
        },
        {
          name: "Eğitim",
          component: <CalistayAnketSirala3 />
        },
        {
          name: "İş Geliştirme",
          component: <CalistayAnketSirala4 />
        },
        {
          name: "AR-GE",
          component: <CalistayAnketSirala5 />
        },
        {
          name: "Kapasite Geliştirme",
          component: <CalistayAnketSirala6 />
        },
        {
          name: "AFET Yönetimi ve İş sürekliliği",
          component: <CalistayAnketSirala7 />
        }
      ]
    },
    {
      name: "Amaç Tanımlamak",
      surveys: [
        {
          name: "Yeşil Dönüşüm",
          component: <CalistayAnketYorum1 idAnket="1" />
        },
        {
          name: "Dijital Dönüşüm",
          component: <CalistayAnketYorum1 idAnket="2" />
        },
        {
          name: "Eğitim",
          component: <CalistayAnketYorum1 idAnket="3" />
        },
        {
          name: "İş Geliştirme",
          component: <CalistayAnketYorum1 idAnket="4" />
        },
        {
          name: "AR-GE",
          component: <CalistayAnketYorum1 idAnket="5" />
        },
        {
          name: "Kapasite Geliştirme",
          component: <CalistayAnketYorum1 idAnket="6" />
        },
        {
          name: "AFET Yönetimi ve İş sürekliliği",
          component: <CalistayAnketYorum1 idAnket="7" />
        }
      ]
    },
    {
      name: "Amaç Ölçmek",
      surveys: [
        {
          name: "Yeşil Dönüşüm",
          component: <CalistayAnket41 idAnket="1" />
        },
        {
          name: "Dijital Dönüşüm",
          component: <CalistayAnket41 idAnket="2" />
        },
        {
          name: "Eğitim",
          component: <CalistayAnket41 idAnket="3" />
        },
        {
          name: "İş Geliştirme",
          component: <CalistayAnket41 idAnket="4" />
        },
        {
          name: "AR-GE",
          component: <CalistayAnket41 idAnket="5" />
        },
        {
          name: "Kapasite Geliştirme",
          component: <CalistayAnket41 idAnket="6" />
        },
        {
          name: "AFET Yönetimi ve İş sürekliliği",
          component: <CalistayAnket41 idAnket="7" />
        }
      ]
    }
  ];

  interface Product {
    id: string;
    name: string;
    inventoryStatus: string;
    progress: number;
    start: string;
    end: string;
    day: string;
  }

  const products: Product[] = [
    {
      id: "1",
      name: "Beklenti ve ihtiyaç analizleri ",
      inventoryStatus: "Sorunsuz",
      progress: 100, // 75% progress
      start: "15.08.2024",
      end: " 26.09.2024",
      day: "42"
    },
    {
      id: "2",
      name: "Amaçların oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 100,
      start: "29.08.2024",
      end: "03.10.2024",
      day: "35"
    },
    {
      id: "3",
      name: "Bilgilendirme ve Çalıştayla",
      inventoryStatus: "Sorunsuz",
      progress: 33,
      start: "12.09.2024",
      end: "5.12.2024",
      day: "84"
    },
    {
      id: "4a",
      name: "Danışmanlık hizmetlerinin oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 100,
      start: "19.09.2024",
      end: "10.10.2024",
      day: "21"
    },
    {
      id: "4b",
      name: "Eğitim sisteminin oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 100,
      start: "26.09.2024",
      end: "17.10.2024",
      day: "21"
    },
    {
      id: "4c",
      name: "İş geliştirme sisteminin oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 100,
      start: "03.10.2024",
      end: "17.10.2024",
      day: "14"
    },
    {
      id: "4d",
      name: "ARGE yapısının oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 90,
      start: "10.10.2024",
      end: "24.10.2024",
      day: "14"
    },
    {
      id: "4e",
      name: "Kurumsal kapasite geliştirme sisteminin kurulması",
      inventoryStatus: "Sorunsuz",
      progress: 90,
      start: "28.11.2024",
      end: "12.12.2024",
      day: "14"
    },
    {
      id: "4f",
      name: "Dijital Dönüşüm stratejisinin oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 10,
      start: "24.10.2024",
      end: "07.11.2024",
      day: "14"
    },
    {
      id: "5",
      name: "Sürdürülebilirlik durum analizi",
      inventoryStatus: "Sorunsuz",
      progress: 0,
      start: "31.10.2024",
      end: "05.12.2024",
      day: "35"
    },
    {
      id: "6",
      name: "Eylem ağ analizi",
      inventoryStatus: "Sorunsuz",
      progress: 40,
      start: "21.11.2024",
      end: "19.12.2024",
      day: "28"
    },
    {
      id: "7",
      name: "Yönetim sisteminin oluşturulması",
      inventoryStatus: "Sorunsuz",
      progress: 25,
      start: "28.11.2024",
      end: "09.01.2025",
      day: "42"
    },
    {
      id: "8",
      name: "Değerlendirme toplantıları",
      inventoryStatus: "Sorunsuz",
      progress: 34,
      start: "29.08.2024",
      end: "23.01.2025",
      day: "147"
    },
    {
      id: "9",
      name: "OSB Sürdürülebilirlik izlem yapısının tasarlanması",
      inventoryStatus: "Sorunsuz",
      progress: 12,
      start: "26.12.2024",
      end: "16.01.2025",
      day: "21"
    },
    {
      id: "10",
      name: "OSB Dijitalleşme izlem yapısının tasarlanması",
      inventoryStatus: "Sorunsuz",
      progress: 10,
      start: "02.01.2025",
      end: "23.01.2025",
      day: "21"
    },
    {
      id: "11",
      name: "Topluluk etkinliklerinin geliştirilmesi",
      inventoryStatus: "Sorunsuz",
      progress: 5,
      start: "09.01.2025",
      end: "23.01.2025",
      day: "14"
    }
  ];

  const progressBodyTemplate = (product: Product) => {
    return <ProgressBar value={product.progress} showValue={true} />;
  };

  const getSeverity = (product: Product) => {
    switch (product.inventoryStatus) {
      case "Sorunsuz":
        return "success";

      case "Gecikti":
        return "warning";

      case "Tanımlandı":
        return "danger";

      case "WEB app bağlantı":
        return "danger";

      default:
        return null;
    }
  };

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="card">
      <div className=" align-items-center justify-content-center p-0 lg:p-5">
        <h1 className="text-4xl text-primary font-bold text-center">Stratejik Plan Çalışması</h1>

        <p className="text-lg text-center mt-3 mb-5" style={{ maxWidth: "" }}>
          Bu çalışma, stratejik hedeflerin belirlenmesi ve yönetimin oluşturulması için kapsamlı bir yaklaşım sunar.
          Detayları keşfetmek için aşağıdaki seçeneklere göz atabilirsiniz.
        </p>

        <div className="flex flex-wrap gap-3 justify-content-center">
          <Button
            label="Takvim"
            icon="pi pi-calendar"
            className="p-button-outlined"
            onClick={() => scrollToSection(section1Ref)}
          />
          <Button
            label="Anketler"
            icon="pi pi-chart-bar"
            className="p-button-outlined"
            onClick={() => scrollToSection(section2Ref)}
          />
          <Button
            label="Raporlar"
            icon="pi pi-file"
            className="p-button-outlined"
            onClick={() => scrollToSection(section3Ref)}
          />
        </div>

        <div className="flex flex-column align-items-center justify-content-center mt-5">
          <div className="flex items-center gap-3 justify-content-center" style={{ alignItems: "center" }}>
            <img
              src="/images/logo-qnb.png"
              alt="QNB"
              className="w-12 h-auto"
              style={{ maxWidth: "120px", maxHeight: "60px", objectFit: "contain" }}
            />
            <p className="text-sm text-gray-600 text-center">
              Bu çalışmaya katkılarından dolayı <strong>QNB</strong>'ye teşekkür ederiz.
            </p>
          </div>
        </div>

        <Divider className="mt-5" />
        <h3 ref={section1Ref} className="text-primary font-bold text-center">
          Stratejik Yönetim Sisteminin Kurulması Zaman Çizelgesi
        </h3>

        <div className="">
          <DataTable
            value={products}
            scrollable
            className="compact-table"
            style={{
              width: "100%",
              overflowX: "auto", // Yalnızca yatay kaydırmayı aktif hale getirir
              overflowY: "hidden" // Dikey kaydırmayı gizler
            }}
          >
            <Column
              field="name"
              header="Kilometre taşı açıklaması"
              bodyClassName="p-1 text-sm"
              style={{ minWidth: "150px" }} // Sütun genişliğini artırabilirsiniz
            />
            {/* <Column header="Koordinasyon" body={statusBodyTemplate}></Column> */}
            <Column
              field="progress"
              header="İlerleme Durumu"
              body={progressBodyTemplate}
              bodyClassName="p-1 text-sm"
              style={{ minWidth: "100px" }}
            />
            <Column field="start" header="Başlangıç" bodyClassName="p-1 text-sm" style={{ minWidth: "100px" }} />
            <Column field="end" header="Bitiş" bodyClassName="p-1 text-sm" style={{ minWidth: "100px" }} />
            <Column field="day" header="Günler" bodyClassName="p-1 text-sm" style={{ minWidth: "100px" }} />
          </DataTable>
        </div>

        {/* Ayrıcı */}
        <Divider className="mt-5" />

        {/* Anketler Başlığı */}
        <h3 ref={section2Ref} className=" text-primary font-bold text-center">
          Stratejik Amaçlar Anketleri
        </h3>

        {/* Anket Kartları */}
        <div className="grid p-1 lg:p-3">
          {groups.map((group, index) => (
            <div key={index} className="col-12 md:col-4">
              <Card title={group.name} className="h-full">
                <div className="flex flex-column gap-2">
                  {group.surveys.map((survey, surveyIndex) => (
                    <Button
                      key={surveyIndex}
                      label={survey.name}
                      className="p-button-outlined p-button-sm w-full"
                      onClick={() => {
                        setSelectedSurvey(survey.component);
                        setVisible(true); // Show the dialog with the selected survey
                      }}
                    />
                  ))}
                </div>
              </Card>
            </div>
          ))}
        </div>

        <Dialog
          header="Anket Detayları"
          visible={visible}
          style={{ width: window.innerWidth < 768 ? "90vw" : "50vw" }}
          onHide={() => setVisible(false)} // Close the dialog when it's hidden
        >
          {selectedSurvey}
        </Dialog>

        <Divider className="mt-5" />
        <h3 ref={section3Ref} className=" text-primary font-bold text-center mb-4">
          Raporlar
        </h3>

        <div className="grid p-1 lg:p-3 justify-content-center">
          {reports.map(report => (
            <div key={report.id} className="col-12 md:col-6">
              <Card
                title={report.title}
                className="h-full"
                footer={
                  <div className="card-footer mb-auto">
                    <Button
                      label="Raporu İndir"
                      icon="pi pi-download"
                      className="p-button-outlined w-full"
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = report.filePath;
                        link.download = report.filePath;
                        link.download = report.title || "file";
                        link.click();
                      }}
                    />
                  </div>
                }
              >
                <div className="relative">
                  <img
                    src={report.imageUrl}
                    alt={report.title}
                    style={{ width: "100%", height: "150px", objectFit: "cover", borderRadius: "8px" }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      display: "block",
                      width: "100%",
                      textAlign: "right" // Sağ hizalama
                    }}
                  >
                    {report.date}
                  </p>
                </div>

                <p
                  className="mt-3 line-clamp-2"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    height: "40px"
                  }}
                >
                  {report.description}
                </p>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StratejikPlanCalismasi;
