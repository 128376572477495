import { useToast } from "../../utils/toast";
import { Fieldset } from "primereact/fieldset";
import { ForumTopicEnum, ForumWithoutMessage, getColorForLetter } from "../../services/forum/forum.dto";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import classNames from "classnames";
import forumService from "../../services/forum/forum.service";

export interface CardDataDto {
  imgSrc: string;
  title: string;
  description: string;
  value: ForumTopicEnum;
}

export const cardData: CardDataDto[] = [
  {
    imgSrc: "/images/landing-page/hesaplama.jpg",
    title: "Son Açılan Konular",
    description: "Kullanıcılar tarafından en son oluşturulan konuları görüntüleyebilirsiniz.",
    value: ForumTopicEnum.GENEL
  },
  {
    imgSrc: "/images/forum/yesil-donusum.jpg",
    title: "Yeşil Dönüşüm",
    description: "Sürdürülebilirlik, çevresel performans, iyileştirme ve malzeme-kaynak yönetimi raporları.",
    value: ForumTopicEnum.YESIL_DONUSUM
  },
  {
    imgSrc: "/images/forum/dijital-donusum.jpg",
    title: "Dijital Dönüşüm",
    description:
      "Yeşil dönüşüm olgunluk analizi, dijital dönüşüm endeksi ve sektör/bölge/ülke genelindeki istatistikler ve raporlar.",
    value: ForumTopicEnum.DIJITAL_DONUSUM
  },
  {
    imgSrc: "/images/landing-page/egitim.jpg",
    title: "Eğitim",
    description: "Sürdürülebim değişikliğine uyum eğitimleri.",
    value: ForumTopicEnum.EGITIM
  },
  {
    imgSrc: "/images/landing-page/afad.jpg",
    title: "İş Sürekliliği",
    description: "Afet durumunda sanayi kuruluşlarının kesintisiz faaliyet göstermesi için AFAD ve Sanayi Bakanlığı ile geliştirdiğimiz işbirliği çalışmaları.",
    value: ForumTopicEnum.IS_GELISTIRME
  },
  {
    imgSrc: "/images/forum/arge.jpg",
    title: "ARGE",
    description: "Türkiye genelindeki organijeler ve sektörel gelişmeler.",
    value: ForumTopicEnum.ARGE
  },
  {
    imgSrc: "/images/forum/kapasite-gelistirme.jpg",
    title: "Kapasite Geliştirme",
    description: "Sanayi kuruluşlarının üretim kapasitelerini artırmaya yönelik stratejik işbirliği çalışmaları.",
    value: ForumTopicEnum.KAPASITE_GELISTIRME
  },
  {
    imgSrc: "/images/forum/is-surekliligi.jpg",
    title: "İş Geliştirme",
    description: "Türkiye genelindeki organize sanayi bölgelerinin iş sürekliliği planları ve sektörel gelişmelere dair bilgiler.",
    value: ForumTopicEnum.IS_SUREKLILIGI
  }
];

const ForumPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCard, setSelectedCard] = useState<CardDataDto>(cardData[0]);
  const [forums, setForums] = useState<ForumWithoutMessage[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    forumService
      .GetForumByTopic(ForumTopicEnum.GENEL, {
        blockUI: true
      })
      .then(res => {
        if (res?.result?.error === null) {
          const sortedTopics = res.result.data.sort(
            (a: ForumWithoutMessage, b: ForumWithoutMessage) =>
              new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
          );
          setForums(sortedTopics);
        } else {
          toast.show(res?.result?.error || "Hata oluştu.", "error");
          setForums([]);
        }
      });
  }, []);

  const itemTemplate = (topic: ForumWithoutMessage, index: number) => {
    const firstLetter = topic.creator_name.charAt(0).toUpperCase();
    const bgColor = getColorForLetter(firstLetter);

    const handleClick = () => {
      navigate(`/app/forum-ayrinti/${topic.id}`);
    };

    return (
      <div className="col-12" key={topic.id}>
        <div
          className={classNames("flex flex-row xl:align-items-start p-0 xl:p-1  gap-2 xl:gap-4", {
            "border-top-1 surface-border": index !== 0
          })}
          onClick={handleClick}
        >
          <div
            className="w-3rem h-3rem flex justify-content-center align-items-center text-white text-xl font-bold border-circle shadow-2 my-1"
            style={{ backgroundColor: bgColor }}
          >
            <div className="flex align-items-center justify-content-center" style={{ height: "100%" }}>
              {firstLetter}
            </div>
          </div>

          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div className="flex flex-column gap-2">
              <div className="text-md xl:text-lg font-bold text-900">{topic.forum_header}</div>

              <div className="flex align-items-center gap-1 text-xs xl:text-sm text-600">
                <i className="pi pi-user" style={{ verticalAlign: "middle" }}></i>
                <span>{topic.creator_name}</span>
                <span>-</span>
                <i className="pi pi-calendar" style={{ verticalAlign: "middle" }}></i>
                <span>{new Date(topic.creation_date).toLocaleDateString()}</span>
              </div>

              {windowWidth < 768 && (
                <div className="flex align-items-center gap-1 text-xs xl:text-sm text-600">
                  <i className="pi pi-comments " style={{ verticalAlign: "middle" }}></i>
                  <span>{topic.replies} cevap</span>
                  {/* <span>-</span>
                  <i className="pi pi-eye" style={{ verticalAlign: "middle" }}></i>
                  <span>{topic.views} görüntülenme</span> */}
                </div>
              )}
            </div>

            {windowWidth > 768 && (
              <div className="flex flex-column align-items-end gap-2 my-1">
                <div className="text-sm text-600 flex items-center">
                  <i className="pi pi-comments mr-2" style={{ verticalAlign: "middle" }}></i>
                  {topic.replies} cevap
                </div>
                <div className="text-sm text-600 flex items-center">
                  <i className="pi pi-eye mr-2" style={{ verticalAlign: "middle" }}></i>
                  {topic.view_count} görüntülenme
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const changeTopic = (card: CardDataDto) => {
    setSelectedCard(card);
    forumService
      .GetForumByTopic(card.value, {
        blockUI: true
      })
      .then(res => {
        if (res?.result?.error === null) {
          const sortedForums = res.result.data.sort(
            (a: ForumWithoutMessage, b: ForumWithoutMessage) =>
              new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
          );
          setForums(sortedForums);
        } else {
          toast.show(res?.result?.error || "Hata oluştu.", "error");
          setForums([]);
        }
      });
  };

  function handleSearch(searchTerm: string): void {
    alert(`Arama yapılacak: ${searchTerm}`);
  }

  return (
    <div className="card p-1 lg:p-3">
      <div className="flex justify-content-between mb-4 mx-0 lg:mx-2">
        {/* Search input and button in the same row */}
        <div className="p-inputgroup flex-1 mr-2">
          <input
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Konuları ara..."
            className="p-inputtext p-component"
          />
          <Button
            label="Ara"
            icon="pi pi-search"
            className="p-button-outlined"
            onClick={() => handleSearch(searchTerm)}
          />
        </div>
        <Button label="Yeni Konu Aç" onClick={() => navigate("/app/forum-ekle")} />
      </div>

      <div id="features" className="py-2 px-1 mt-2 mx-0 lg:mx-2">
        <div className="grid justify-content-center">
          <div className="grid">
            {cardData.map((card, index) => (
              <div className="col-12 md:col-6 lg:col-3" key={index}>
                <div
                  className="card hover-card cursor-pointer"
                  style={{ height: "8rem", padding: "1rem" }}
                  onClick={() => changeTopic(card)}
                >
                  <div className="flex align-items-center">
                    <img src={card.imgSrc} className="shadow-2 w-6rem h-6rem mr-3" />
                    <div className="flex flex-column justify-content-center">
                      <h5 className="mb-1 text-900 text-lg">{card.title}</h5>
                      {/* <span className="text-600 text-sm">{card.description}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="">
        <Fieldset legend={selectedCard.title} className="mb-4 shadow-lg border-round">
          <div className="text-black-alpha-90 text-xl text-base mb-4">{selectedCard.description}</div>

          <DataView
            value={forums}
            itemTemplate={itemTemplate as any}
            layout="list"
            paginator
            rows={10}
            emptyMessage="Listelenecek konu bulunamadı."
          />
        </Fieldset>
      </div>
    </div>
  );
};

export default ForumPage;
