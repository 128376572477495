import React from "react";
import { Card } from "primereact/card";
import aosbLogo from "../assets/images/aosb_ces_logo.jpg";

const FirmCard = (props: {
  title: string;
  imageSrc: string;
  redirectUrl: string;
  imageAlt: string;
  imageSize: string;
}) => {
  return (
    <Card
      header={
        <div className="flex flex-column align-items-center justify-content-center">
          <span className="mb-2 mt-2 font-bold text-lg text-center text-black-alpha-90">
            {props.title}
          </span>
          <img className={props.imageSize} alt={props.imageAlt} src={props.imageSrc} />
        </div>
      }
      className="w-full sm:w-12rem h-auto sm:h-12rem hover:shadow-3 cursor-pointer transition-all m-3"
      onClick={() => (window.location.href = props.redirectUrl)}
    />
  );
};

const ProtokolFirmalarComponent = () => {
  return (
    <div className="card">
      <div className="w-full surface-card px-2 sm:px-8" style={{ borderRadius: "53px" }}>
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Protokol Yapılan Firmalar</div>
        </div>
        <div className="flex flex-wrap align-items-center justify-content-center">
          <div className="grid grid-nogutter justify-content-center p-2">
            <FirmCard
              title="ASO 2. VE 3 OSB"
              imageSrc="/images/firma/asobcev.png"
              redirectUrl="https://www.asobcev.com.tr/"
              imageAlt="ASO 2-3 OSB Logo"
              imageSize="w-8rem h-7rem mt-3"
            />
            <FirmCard
              title="ADANA ADANA HACI SABANCI OSB"
              imageSrc={aosbLogo}
              redirectUrl="http://www.aosb-co2.com/"
              imageAlt="ADANA OSB"
              imageSize="w-8rem h-5rem mt-3"
            />
            <FirmCard
              title="TOBB MEYBEM"
              imageSrc="/images/firma/meybem-logo.svg"
              redirectUrl="https://meybem.org.tr/kurumsal-karbon-ayak-izi-egitimi-ankara/"
              imageAlt="TOBB MEYBEM"
              imageSize="w-10rem h-8rem"
            />
            <FirmCard
              title="Cimpact Pro"
              imageSrc="https://cimpactpro.com/view/default/assets/img/h50.png"
              redirectUrl="https://cimpactpro.com/ozel-cozumler/osb"
              imageAlt="Cimpact Pro"
              imageSize="w-10rem h-4rem mt-5"
            />
            <FirmCard
              title="GREENJOBS BİLGİ TEKNOLOJİLERİ "
              imageSrc="/images/firma/greenjobs.png"
              redirectUrl="https://www.greenjobs.com.tr"
              imageAlt="GREENJOBS"
              imageSize="w-8rem h-4rem mt-3"
            />
            <FirmCard
              title="ABM YÖNETİM DANIŞMANLIK A.Ş."
              imageSrc="/images/firma/abs.png"
              redirectUrl="https://abmdanismanlik.com.tr"
              imageAlt="abs"
              imageSize="w-8rem h-4rem mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtokolFirmalarComponent;
