import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { Link, useNavigate } from "react-router-dom";
import { CarouselResponsiveOption, Carousel } from "primereact/carousel";
import "../../assets/styles/hover.scss";
import { Menu } from "primereact/menu";
import "../../assets/styles/hover.scss";
import { useAuth } from "../../utils/auth";
import Header from "./Header";
interface ItemDto {
  name: string;
  description: string;
  image: string;
  link: string;
}
const cardData = [
  {
    imgSrc: "/images/landing-page/hesaplama.jpg",
    title: "Hesaplama",
    description: "Yeşil dönüşüm ve sürdürülebilirlik politikalarınızı planlamak için gerekli hesaplamalar.",
    path: "/app/karbonayakizi"
  },
  {
    imgSrc: "/images/landing-page/rapor.jpg",
    title: "Raporlama",
    description: "Sürdürülebilirlik, çevresel performans, iyileştirme ve malzeme-kaynak yönetimi raporları.",
    path: "/app/rapor"
  },
  {
    imgSrc: "/images/landing-page/analiz.jpg",
    title: "Analiz",
    description:
      "Yeşil dönüşüm olgunluk analizi, dijital dönüşüm endeksi ve sektör/bölge/ülke genelindeki istatistikler ve raporlar.",
    path: "/app/grafik"
  },
  {
    imgSrc: "/images/landing-page/egitim.jpg",
    title: "Eğitim",
    description: "Sürdürülebilirlik, yenilikçilik, teknoloji, kurumsal gelişim ve iklim değişikliğine uyum eğitimleri.",
    path: "/app/egitim"
  },
  {
    imgSrc: "/images/landing-page/afad.jpg",
    title: "İş Sürekliliği Planı",
    description:
      "Afet durumunda sanayi kuruluşlarının kesintisiz faaliyet göstermesi için AFAD ve Sanayi Bakanlığı ile geliştirdiğimiz işbirliği çalışmaları.",
    path: "/app/is-surekliligi"
  },
  {
    imgSrc: "/images/landing-page/haber.jpg",
    title: "OSBÜK Bülten",
    description:
      "Türkiye genelindeki organize sanayi bölgeleriyle ilgili güncel haberler, projeler ve sektörel gelişmeler.",
    path: "/app/bulten"
  }
];

const crouselItems: any = [
  {
    name: "Stratejik plan raporu",
    description: "Paydaş beklenti ve ihtiyaç analizi (2.10.2024)",
    image: "/images/landing-page/stratejikplan.jpg"
    //link: "/app/stratejik-plan-raporu"
  },
  // {
  //   name: "Stratejik plan çalıştayı",
  //   description: "Amaçlar çalıştay çalışmaları (31 Ekim - 3 Kasım 2024)",
  //   image: "/images/landing-page/sunum.jpg",
  //   link: "/calistay/home"
  // },
  {
    name: "Stratejik Plan Çalışması",
    description: "Stratejik Plan Çalışması.",
    image: "/images/landing-page/stratejik-plan.jpg",
    link: "/app/stratejik-plan"
  },
  {
    name: "Forum",
    description: "Forum",
    image: "/images/landing-page/forum.jpg",
    link: "/app/forum-page"
  },
  {
    name: "Hesaplama",
    description: "Gerekli hesaplamalara buradan ulaşabilirsiniz.",
    image: "/images/landing-page/hesaplama.jpg",
    link: "/app/karbonayakizi"
  },
  {
    name: "Raporlama",
    description: "Sürdürülebilirlik ve çevresel performans raporları.",
    image: "/images/landing-page/rapor.jpg",
    link: "/app/rapor"
  },
  {
    name: "Analiz",
    description: "Yeşil dönüşüm olgunluk analizi ve istatistikler.",
    image: "/images/landing-page/analiz.jpg",
    link: "/app/grafik"
  },
  {
    name: "Eğitim",
    description: "Geniş kapsamlı eğitim fırsatları",
    image: "/images/landing-page/egitim.jpg",
    link: "/app/egitim"
  },
  {
    name: "İş Sürekliliği",
    description: "Amaçlar çalıştay çalışmaları (31 Ekim - 3 Kasım 2024)",
    image: "/images/landing-page/afad.jpg",
    link: "/app/is-surekliligi"
  },
  {
    name: "OSBÜK Bülten",
    description: "Güncel OSB haberleri bülteni",
    image: "/images/landing-page/haber.jpg",
    link: "/app/bulten"
  }
];

const LandingPage = () => {
  const [isHidden, setIsHidden] = useState(false);
  const { layoutConfig } = useContext(LayoutContext);
  const menuRef = useRef<HTMLElement | null>(null);
  const navigate = useNavigate();
  const auth = useAuth();

  const toggleMenuItemClick = () => {
    setIsHidden(prevState => !prevState);
  };

  const itemTemplate = (item: ItemDto) => {
    return (
      <a
        href={item.link || "#"}
        className="border-round-2xl border-3 border-primary-500 surface-border border-round m-2 text-center py-3 px-3 h-auto block transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-3"
      >
        <div className="mb-3">
          <img src={item.image} alt={item.name} className="w-8rem h-8rem object-cover rounded-full mx-auto shadow-2" />
        </div>
        <div className="text-center">
          <h4 className="mb-2 text-xl font-semibold" style={{ maxHeight: "2em", minHeight: "2em" }}>
            {item.name}
          </h4>
          <p
            className="text-sm overflow-hidden text-ellipsis line-clamp-2"
            style={{ maxHeight: "3em", minHeight: "3em" }}
          >
            {item.description}
          </p>
        </div>
      </a>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
      numScroll: 5
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: "560px",
      numVisible: 1,
      numScroll: 1
    }
  ];

  //const username = localStorage.getItem("userName");
  const [menuVisible, setMenuVisible] = useState(false);
  const items = [
    {
      label: "Giriş Yap",
      icon: "pi pi-sign-in",
      command: () => navigate("/loginsebis"),
      visible: true
    },
    {
      label: "Kayıt Ol",
      icon: "pi pi-user-plus",
      command: () => {
        auth.removeUserFromStorage();
        navigate("/register", { replace: true });
      }
      //visible: username ? true : false
    }
  ];

  return (
    <div className="surface-0 flex justify-content-center">
      <div id="home" className="landing-wrapper p-fluid overflow-hidden">
        {/* <div
          className="py-1 px-4 mx-0 flex align-items-center justify-content-between relative lg:static"
          style={{ backgroundColor: "#114d6b", height: "60px" }}
        > */}
        <div className="py-2 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
          <Link to="/" className="flex align-items-center">
            <img src="/images/logoosbuk.png" alt="OSBÜK" height="50" className="mr-3 lg:mr-3" />
            {/* <span className="text-900 font-medium text-2xl line-height-3 mr-8">SEBİS</span> */}
          </Link>

          <div className="lg:flex ml-auto">
            <div className="md:flex hidden">
              <Button
                label="Giriş Yap"
                text
                rounded
                className="border-none font-light line-height-2 text-blue-500 md:mr-2"
                style={{
                  backgroundColor: "#ffffff", // White background
                  color: "#114d6b", // Text color #114d6b
                  border: "3px solid #114d6b",
                  height: "40px",
                  width: "120px"
                }}
                onClick={() => navigate("/loginsebis")}
                onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              />
              <Button
                label={"Kayıt Ol"}
                rounded
                className="border-none font-light line-height-2 bg-blue-500 text-white"
                style={{
                  backgroundColor: "#ffffff", // White background
                  color: "#114d6b", // Text color #114d6b
                  border: "3px solid #114d6b",
                  height: "40px",
                  width: "120px"
                }}
                onClick={() => navigate("/register")}
                onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              />
            </div>

            <div className="md:hidden">
              <Button
                icon="pi pi-bars"
                onClick={() => setMenuVisible(!menuVisible)}
                className="p-button-rounded p-button-outlined"
                style={{ backgroundColor: "#ffffff", color: "#114d6b", border: "3px solid #114d6b" }}
              />
              {menuVisible && (
                <Menu
                  model={items}
                  className="absolute z-5 w-full"
                  style={{
                    top: "50px", // Düğmeden biraz aşağıda
                    left: "0",
                    backgroundColor: "#fff",
                    border: "1px solid #ddd"
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {/* <div style={{ backgroundColor: "#114d6b", height: "4px" }}>
          <br />
        </div> */}

        {/* <div
          id="features"
          className="flex justify-content-center align-items-center py-4 px-4 lg:px-6 border-green-300 rounded-lg shadow-md"
          style={{
            backgroundColor: "#114d6b",
            backgroundImage: "url('images/bgi.webp')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "25rem"
          }}
        >
          <div className="text-center">
            <h1 className="text-3xl font-bold" style={{ color: "#114d6b" }}>
              YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ
            </h1>
            <p className="mt-2" style={{ color: "#114d6b" }}>
              Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
              adımlarla ilerleyen bir topluluk inşa ediyoruz.
            </p>
          </div>
        </div> */}

        {/* <div
          id="features"
          className="flex justify-center items-center py-8 px-6 rounded-lg shadow-md relative overflow-hidden" // py-8 daha fazla dikey boşluk
          style={{
            backgroundColor: "#114d6b", 
            backgroundImage: "url(images/bgi.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "25rem"
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40"></div>
          <div className="text-center relative z-10">
            <h1 className="text-3xl font-bold text-white">YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ</h1>
            <p className="mt-4 text-white">
              Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
              adımlarla ilerleyen bir topluluk inşa ediyoruz.
            </p>
          </div>
        </div> */}

        <Header />

        {/* <div id="header" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid grid-nogutter align-items-center">
            <div className="col-12 md:col-6">
              <h1 className="text-4xl font-bold text-800 mb-3 text-center">YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ</h1>
              <p className="text-lg text-600 mb-4 text-center">
                Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
                adımlarla ilerleyen bir topluluk inşa ediyoruz.
              </p>
              <Button
                label="Giriş Yap"
                text
                rounded
                className="border-none font-light line-height-2 bg-blue-500 text-white "
                style={{
                    backgroundColor: "#ffffff", // White background
                    color: "#114d6b", // Text color #114d6b
                    border: "3px solid #114d6b",
                    height: "40px",
                    width: "120px"
                  }}
                onClick={() => navigate("/loginsebis")}
                onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              />
            </div>

            <div className="col-12 md:col-6">
              <img
                src="images/landing-page/hero2.png"
                alt="Hero Image"
                className="w-full h-auto rounded-lg shadow-2"
              />
            </div>
          </div>
        </div> */}

        {/* <div
          id="hero"
          className="flex flex-column pt-4 px-4 lg:px-8 overflow-hidden"
          style={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EEEFAF 0%, #C3E3FA 100%)",
            clipPath: "ellipse(150% 87% at 93% 13%)"
          }}
        >
          <div className="mx-4 md:mx-8 mt-0 md:mt-4">
            <h1 className="text-4xl font-bold text-gray-900 line-height-2">
              YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ
            </h1>
            <p className="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">
              Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
              adımlarla ilerleyen bir topluluk inşa ediyoruz.
            </p>
          </div>
          <div className="flex justify-content-center md:justify-content-end">
            <img src="images/landing-page/hero3.webp" alt="Hero Image" className="w-9 md:w-6" />
          </div>
        </div> */}

        {/* <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="grid">
              {cardData.map((card, index) => (
                <div className="col-12 md:col-6 lg:col-4 " key={index}>
                    
                  <div
                    className="card  hover-card cursor-pointer"
                    style={{ height: "8rem", padding: "1rem" }}
                    onClick={() => navigate(card.path)}
                  >
                    <div className="flex align-items-center">
                      <img src={card.imgSrc} className="shadow-2 w-6rem h-6rem mr-3" />
                      <div className="flex flex-column justify-content-center">
                        <h5 className="mb-1 text-900 text-lg">{card.title}</h5>
                        <span className="text-600 text-sm">{card.description}</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="grid">
              {cardData.map((card, index) => (
                <div className="col-12 md:col-6 lg:col-4 " key={index}>
                  <div
                    style={{
                      height: "130px",
                      padding: "2px",
                      borderRadius: "10px",
                      background:
                        "linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))"
                    }}
                  >
                    <div
                      className="p-3 surface-card h-full cursor-pointer hover-card"
                      style={{ borderRadius: "8px" }}
                      onClick={() => navigate(card.path)}
                    >
                      <div className="flex align-items-center">
                        <img src={card.imgSrc} className="shadow-2 w-6rem h-6rem mr-3" />
                        <div className="flex flex-column justify-content-center">
                          <h5 className="mb-1 text-900 text-lg">{card.title}</h5>
                          <span className="text-600 text-sm">{card.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            <div className="grid">
              {cardData.map((card, index) => (
                <div className="col-12 md:col-6 lg:col-4" key={index}>
                  <div
                    className="card hover-card cursor-pointer"
                    style={{
                      height: "8rem",
                      padding: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" ,
                      transition: "transform 0.2s ease, box-shadow 0.2s ease",
                      backgroundColor: "#ffffff"
                    }}
                    onClick={() => navigate(card.path)}
                    onMouseEnter={e => {
                      e.currentTarget.style.transform = "translateY(-2px)"; 
                      e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)"; 
                      e.currentTarget.style.borderColor = "#114d6b"; 
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.transform = "translateY(0)"; 
                      e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
                      e.currentTarget.style.borderColor = "#ccc"; 
                    }}
                    onMouseDown={e => {
                      e.currentTarget.style.transform = "translateY(1px)";
                      e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
                    }}
                    onMouseUp={e => {
                      e.currentTarget.style.transform = "translateY(0)";
                      e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
                    }}
                  >
                    <div className="flex align-items-center">
                      <img src={card.imgSrc} className="shadow-2 w-6rem h-6rem mr-3" />
                      <div className="flex flex-column justify-content-center">
                        <h5 className="mb-1 text-900 text-lg">{card.title}</h5>
                        <span className="text-600 text-sm">{card.description}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div> */}

        <div id="features" className="lg:px-4 mt-4 lg:mx-4">
          <div className="grid justify-content-center">
            <div
              className="col-12 mt-2 mb-2 md:p-4"
              style={{
                borderRadius: "20px",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%)"
              }}
            >
              <div className="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">
                <a href="/app/baskan-mesaj">
                  <img
                    src="images/baskanmesajnew.png"
                    alt="Baskan Mesaj"
                    className="w-full h-auto" // Makes the image responsive
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="features" className="py-4 px-4 mt-5 mx-0">
          <div className="flex align-items-center justify-content-center">
            <a href="/app/baskan-mesaj">
              <img
                src="images/baskanmesaj.jpg"
                alt="Baskan Mesaj"
                className="w-full h-auto" // Makes the image responsive
              />
            </a>
          </div>
        </div> */}

        <div
          id="features"
          className="py-4 px-6 mt-6 mx-0 bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 rounded-lg shadow-xl"
        >
          <Carousel
            value={crouselItems}
            numVisible={6}
            responsiveOptions={responsiveOptions}
            className="rounded-lg"
            circular
            autoplayInterval={3000}
            itemTemplate={itemTemplate}
          />
        </div>

        <div
          id="features"
          className="py-4 px-6 mt-6 mx-6 bg-gradient-to-r from-blue-800 via-blue-700 to-blue-600 rounded-lg shadow-xl"
        >
          <div className="grid justify-content-between">
            <div className="col-12 md:col-2" style={{ marginTop: "-1.5rem" }}>
              <Link
                to="/"
                className="flex align-items-center justify-content-center md:justify-content-start  cursor-pointer"
              >
                <img src="images/medeasofticon.jpg" alt="footer sections" width="80" height="80" className="mr-2" />
                <span className="font-medium text-3xl text-900">MedeaSoft</span>
              </Link>
            </div>

            <div className="col-12 md:col-3 mt-4 md:mt-0">
              <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Resmi Web Siteleri</h4>
              <a
                className="line-height-3 text-xl block cursor-pointer text-700 hover:text-blue-600 transition-all duration-300"
                href="https://osbuk.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                OSBÜK
              </a>
              <a
                className="line-height-3 text-xl block cursor-pointer text-700 hover:text-blue-600 transition-all duration-300"
                href="https://www.sanayi.gov.tr/anasayfa"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sanayi ve Teknoloji Bakanlığı
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
