import React from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { LoginDto } from "../../services/auth/auth.dto";
import authService from "../../services/auth/auth.service";
import { useAuth } from "../../utils/auth";
import { useToast } from "../../utils/toast";
import axios from "axios";

const LoginOsbuk = () => {
  const auth = useAuth();
  const toast = useToast();

  const navigate = useNavigate();
  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center h-auto w-full p-0"
  );

  const postData = async (formik: any) => {
    const url = "https://www.osbuk.org/view/kurumsal/osb_giris_ok.php";

    const data = {
      email: formik.values.email, // Get email from formik values
      sifre: formik.values.password, // Get password from formik values
      captcha: "asd" // Static captcha for now
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json"
          // Add other headers if necessary
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const osbukLoginFormik = useFormik<LoginDto>({
    initialValues: {
      email: "",
      password: ""
    },
    enableReinitialize: true,
    validate: data => {
      const errors: any = {};

      if (!data.email) {
        errors.email = "E-Posta gereklidir";
      }

      if (!data.password) {
        errors.password = "Şifre gereklidir";
      }

      return errors;
    },
    onSubmit: async values => {
      try {
        //Api isteğini at sonuc 1 ise yap işlemleri

        //const result = await postData(formik);

        const response = 1;
        if (response === 1) {
          authService.CreateTokenOsb(values).then(async res => {
            if (res.result && !res.result.error) {
              auth.removeUserFromStorage();
              auth.setToken(res.result.data, true);

              localStorage.setItem("userName", values.email);
              //localStorage.setItem("role", res.result.data.role);

              navigate("/", { replace: true });
            } else {
              toast.show(res?.result?.error || "Giriş Yapılamamıştır", "error");
            }
          });
        } else {
          toast.show("Login failed", "error"); // Show error if login fails
        }
      } catch (error) {
        console.error("Login request failed:", error);
        toast.show("An error occurred while logging in", "error");
      }
    }
  });

  return (
    
    // <form
    //   key="osbuk-login-form"
    //   className={containerClassName}
    //   onSubmit={osbukLoginFormik.handleSubmit}
    //   style={{ margin: 0, padding: 0 }}
    // >
    //   <div
    //     style={{
    //       borderRadius: "56px",
    //       padding: "0.3rem",
    //       background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
    //     }}
    //   >
    //     <div className="w-full surface-card py-5 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
    //       <div>
    //         <div className="text-center mb-5">
    //           <Link to="/">
    //             <img src="/images/logoosbuk.png" alt="Image" height="50" className="mb-3" />
    //           </Link>
    //           <div className="text-900 text-3xl font-medium mb-3">OSB KULLANICI GİRİŞİ</div>
    //           <span className="text-600 font-medium">OSBÜK.net Hesabınız ile Giriş Yapınız</span>
    //         </div>

    //         <div>
    //           <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
    //             {"E-Posta"}
    //           </label>
    //           <InputText
    //             id="email"
    //             type="text"
    //             placeholder={"E-Postanızı Giriniz"}
    //             className="w-full md:w-30rem"
    //             value={osbukLoginFormik.values.email}
    //             onChange={e => osbukLoginFormik.setFieldValue("email", e.target.value)}
    //             style={{ padding: "1rem" }}
    //             autoComplete="email"
    //           />
    //           {osbukLoginFormik.errors.email && <div className="p-error mt-3">{osbukLoginFormik.errors.email}</div>}

    //           <label htmlFor="password" className="block text-900 font-medium text-xl mb-2 mt-5">
    //             {"Şifre"}
    //           </label>
    //           <div className="login-password">
    //             <Password
    //               inputId="password"
    //               value={osbukLoginFormik.values.password}
    //               onChange={e => osbukLoginFormik.setFieldValue("password", e.target.value)}
    //               placeholder={"Şifrenizi Giriniz"}
    //               className="w-full"
    //               toggleMask={true}
    //               feedback={false}
    //               inputClassName="w-full p-3 md:w-30rem"
    //             ></Password>
    //             {osbukLoginFormik.errors.password && (
    //               <div className="p-error mt-3">{osbukLoginFormik.errors.password}</div>
    //             )}
    //           </div>

    //           <Button label={"Giriş Yap"} className="w-full p-3 text-xl mt-5" type="submit"></Button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </form>


  <div className="grid" style={{ margin: 0, padding: 0 }}>
      <div
        className="col-12 xl:col-6 flex justify-content-center align-items-center h-screen"
        style={{ overflowY: "auto", maxHeight: "100vh" }}
      >
        <form
          key="sebis-login-form"
          className={containerClassName}
          onSubmit={osbukLoginFormik.handleSubmit}
          style={{ margin: 0, padding: 0 }}
        >
          <div
            style={{
              borderRadius: "56px",
              padding: "0.3rem",
              background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
            }}
          >
            <div className="w-full surface-card py-5 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
              <div>
                <div className="text-center mb-5">
                  <Link to="/">
                    <img src="/images/logoosbuk.png" alt="Image" height="50" className="mb-3" />
                  </Link>
                  <div className="text-900 text-3xl font-medium mb-3">SEBİS Giriş Formu</div>
                  <span className="text-600 font-medium">SEBİS Hesabınız ile Giriş Yapınız</span>
                </div>

                <div>
                  <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                    {"E-Posta"}
                  </label>
                  <InputText
                    id="email"
                    type="text"
                    placeholder={"E-Postanızı Giriniz"}
                    className="w-full md:w-30rem"
                    value={osbukLoginFormik.values.email}
                    onChange={e => osbukLoginFormik.setFieldValue("email", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {osbukLoginFormik.errors.email && <div className="p-error mt-3">{osbukLoginFormik.errors.email}</div>}

                  <label htmlFor="password" className="block text-900 font-medium text-xl mb-2 mt-5">
                    {"Şifre"}
                  </label>
                  <div className="login-password">
                    <Password
                      inputId="password"
                      value={osbukLoginFormik.values.password}
                      onChange={e => osbukLoginFormik.setFieldValue("password", e.target.value)}
                      placeholder={"Şifrenizi Giriniz"}
                      className="w-full"
                      toggleMask={true}
                      feedback={false}
                      inputClassName="w-full p-3 mr-6 md:mr-0 md:w-30rem"
                    ></Password>
                    {osbukLoginFormik.errors.password && (
                      <div className="p-error mt-3">{osbukLoginFormik.errors.password}</div>
                    )}
                  </div>

                  <div>
                    <Button label={"Giriş Yap"} className="w-full p-3 text-xl mt-5" type="submit"></Button>

                    <div className="w-full mt-3 text-center">
                      <Link
                        to="/register"
                        className="font-medium no-underline ml-2 text-right cursor-pointer"
                        style={{ color: "var(--primary-color)" }}
                      >
                        Hesabın yok mu? Kaydol
                      </Link>
                    </div>

                    {/* Ekstra Butonlar */}
                    {/* <div className="flex justify-content-between mt-2">
                      <Button
                        label="OSBÜK.net ile Giriş"
                        className="w-6 mr-1 text-md justify-content-center"
                        onClick={() => {
                          navigate("/loginosbuk");
                        }}
                      />
                      <Button
                        label="Kayıt Ol"
                        className="w-6 ml-1 text-md justify-content-center"
                        onClick={() => {
                          navigate("/register");
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <img
        src="/images/login.jpg"
        alt="Image"
        className="col-6 hidden xl:block"
        style={{
          objectFit: "cover",
          margin: 0,
          padding: 0,
          height: "100vh"
        }}
      />
    </div>

  );
};

export default LoginOsbuk;
