const DropdownDatas = {
  GetMuvekkilType() {
    return [
      { label: "Gerçek", value: 1 },
      { label: "Tüzel", value: 2 }
    ];
  },

  GetAddressType() {
    return [
      { label: "Ev", value: "Ev" },
      { label: "İş Yeri", value: "İş Yeri" },
      { label: "Okul", value: "Okul" },
      { label: "Cezaevi", value: "Cezaevi" },
      { label: "Diğer", value: "Diğer" }
    ];
  },

  GetCinsiyet() {
    return [
      { label: "Erkek", value: "Erkek" },
      { label: "Kadın", value: "Kadin" }
    ];
  },

  getOSBList() {
    return [
      "*OSB DIŞI",
      "ADANA GIDA İHTİSAS",
      "ADANA HACI SABANCI",
      "ADANA KARATAŞ TDİ (SU ÜRÜNLERİ)",
      "ADANA TDİ (SERA)",
      "ADIYAMAN",
      "ADIYAMAN GÖLBAŞI",
      "ADIYAMAN KAHTA",
      "AFYONKARAHİSAR",
      "AFYONKARAHİSAR BOLVADİN",
      "AFYONKARAHİSAR BOLVADİN TDİ(BESİ)",
      "AFYONKARAHİSAR DAZKIRI DOKUMA VE KONFEKSİYON",
      "AFYONKARAHİSAR EMİRDAĞ",
      "AFYONKARAHİSAR MERKEZ II",
      "AFYONKARAHİSAR-DİNAR",
      "AĞRI",
      "AĞRI DİYADİN (SERA) TDİ (JEOTERMAL)",
      "AKÇAKOCA DEMİR ÇELİK İHTİSAS",
      "AKHİSAR",
      "AKHİSAR ZEYTİN VE ZEYTİNYAĞI VE TÜREVLERİ İHTİSAS",
      "AKSARAY",
      "AKSARAY ESKİL TUZ GÖLÜ",
      "AKSARAY GÜLAĞAÇ GÜLPINAR",
      "AKSARAY KARKIN TDİ (JEO.KAYN.SERA)",
      "AKSARAY ORTAKÖY YUNUS EMRE",
      "AKSARAY SARIYAHŞİ",
      "AKSARAY SULTANHANI",
      "AKŞEHİR",
      "AKŞEHİR GIDA İHTİSAS",
      "ALİAĞA KİMYA İHTİSAS VE KARMA",
      "AMASYA",
      "AMASYA MERZİFON",
      "ANADOLU",
      "ANAMUR",
      "ANKARA DÖKÜMCÜLER İHTİSAS",
      "ANKARA SANAYİ ODASI 2.VE 3.",
      "ANKARA SANAYİ ODASI I.",
      "ANKARA UZAY VE HAVACILIK İHTİSAS",
      "ANKARA-ÇUBUK TDİ (BESİ)",
      "ANKARA-İVEDİK",
      "ANTAKYA",
      "ANTALYA",
      "ARDAHAN",
      "ARTVİN ARHAVİ",
      "ASIM KİBAR",
      "AYDIN",
      "AYDIN ASTİM",
      "AYDIN EFELER TDİ (JEO.ISITMALI.SERA)",
      "AYDIN-NAZİLLİ",
      "BAĞYURDU (BAYOSB)",
      "BALIKESİR",
      "BALIKESİR AYVALIK GIDA VE GIDA İŞLEME İHTİSAS",
      "BALIKESİR BİGADİÇ JEOTERMAL KAYNAKLI TDI (SERA) OSB",
      "BALIKESİR EDREMİT TDİ(JEO.KAYN.SERA)",
      "BALIKESİR GÖNEN TDİ (JEO.KAYN.SERA)",
      "BANAZ",
      "BANDIRMA",
      "BARAKFAKİH",
      "BARTIN MERKEZ I",
      "BAŞKENT",
      "BATMAN İLİ",
      "BAYBURT",
      "BERGAMA",
      "BESNİ",
      "BEYLİKDÜZÜ",
      "BEYPAZARI",
      "BEYŞEHİR",
      "BİGA",
      "BİLECİK 3.",
      "BİLECİK BOZÜYÜK",
      "BİLECİK I.",
      "BİLECİK II",
      "BİNGÖL",
      "BİRECİK",
      "BİRLİK",
      "BİTLİS",
      "BİYOTEKNOLOJİ İHTİSAS",
      "BOLU KARMA VE TEKSTİL İHTİSAS",
      "BOYABAT",
      "BOZOK",
      "BUCA EGE",
      "BUHARKENT",
      "BURDUR",
      "BURDUR II.",
      "BURDUR-BUCAK",
      "BURHANİYE ZEYTİN VE ZEYTİN ÜRÜNLERİ İŞLEME İHTİSAS VE KARMA",
      "BURSA",
      "BURSA DERİ İHTİSAS VE KARMA",
      "BURSA TEKNOLOJİ (BURSA TEKNOSAB)",
      "BÜYÜKKARIŞTIRAN",
      "CEYHAN",
      "ÇANAKKALE",
      "ÇANAKKALE AYVACIK TDİ (JEO.KAYN.SERA)",
      "ÇANKIRI KORGUN",
      "ÇANKIRI ORTA",
      "ÇANKIRI YAKINKENT",
      "ÇARŞAMBA",
      "ÇERKEŞ",
      "ÇERKEZKÖY",
      "ÇİLİMLİ",
      "ÇİNE",
      "ÇİVRİL",
      "ÇORLU 1",
      "ÇORLU DERİ İHTİSAS VE KARMA",
      "ÇORUM",
      "ÇORUM SUNGURLU",
      "ÇUMRA",
      "DARENDE",
      "DEMİRAĞ",
      "DEMİRCİ",
      "DEMİRTAŞ",
      "DENİZLİ",
      "DENİZLİ ÇARDAK",
      "DENİZLİ DERİ İHTİSAS VE KARMA",
      "DENİZLİ MAKİNE İHTİSAS",
      "DENİZLİ-SARAYKÖY TDİ (JEOTERMAL)",
      "DİKİLİ TDİ (JEO.KAYN.SERA)",
      "DİYARBAKIR",
      "DİYARBAKIR KARACADAĞ",
      "DİYARBAKIR TDİ (BESİ-SÜT)",
      "DİYARBAKIR TEKSTİL İHTİSAS",
      "DURSUNBEY",
      "DÜZCE",
      "DÜZCE GÜMÜŞOVA",
      "DÜZCE II.",
      "DÜZİÇİ KARMA",
      "EDİRNE",
      "ELAZIĞ",
      "ELAZIĞ HAYVAN ÜRÜNLERİ",
      "ELAZIĞ TDİ (BESİ)",
      "ELAZIĞ TEKNOVA",
      "ELBİSTAN",
      "ELMADAĞ MOBİLYACILAR İHTİSAS",
      "ERBAA TOPRAK SANAYİ İHTİSAS VE KARMA",
      "ERCİŞ",
      "ERCİYES",
      "ERGENE-1",
      "ERGENE-2",
      "ERKENEZ",
      "ERZİNCAN",
      "ERZİNCAN TDİ (BESİ)",
      "ERZURUM MERKEZ I.",
      "ERZURUM MERKEZ II",
      "ESKİPAZAR",
      "ESKİŞEHİR",
      "ESKİŞEHİR-BEYLİKOVA BESİ",
      "EVRENSEKİZ",
      "EZİNE GIDA İHTİSAS",
      "FATSA",
      "FERİZLİ",
      "FUAT OKTAY",
      "GAZİANTEP",
      "GAZİANTEP AYAKKABI TERLİK VE YAN SANAYİ İHTİSAS",
      "GAZİANTEP BESİ",
      "GAZİANTEP OĞUZELİ",
      "GEBZE",
      "GEDİZ",
      "GEREDE",
      "GEREDE DERİ İHTİSAS",
      "GİRESUN",
      "GİRESUN 2.",
      "GİRESUN 3.",
      "GİRESUN 4.",
      "GLOBAL KARMA",
      "GÖLPAZARI GIDA İHTİSAS",
      "GÖNEN DERİ İHTİSAS VE KARMA",
      "GÜMÜŞHANE",
      "HAKKARİ YÜKSEKOVA",
      "HASANAĞA",
      "HATAY ALTINÖZÜ ENEK",
      "HATAY ALTINÖZÜ ENEK (SÜT) TDİ",
      "HATAY ERZİN",
      "HATAY HASSA",
      "HATAY İSKENDERUN II.",
      "HATAY KIRIKHAN",
      "HATAY PAYAS",
      "HAYRABOLU",
      "IĞDIR",
      "ISPARTA DERİ İHTİSAS VE KARMA",
      "ISPARTA SÜLEYMAN DEMİREL",
      "ISPARTA-YALVAÇ",
      "İNEGÖL",
      "İNEGÖL MOBİLYA AĞAÇ İŞLERİ İHTİSAS",
      "İPSALA",
      "İSCEHİSAR MERMER İHTİSAS",
      "İSKENDERUN",
      "İSKİLİP",
      "İSLAHİYE",
      "İSTANBUL ANADOLU YAKASI",
      "İSTANBUL DERİ",
      "İSTANBUL DUDULLU",
      "İSTANBUL İKİTELLİ",
      "İSTANBUL TUZLA",
      "İSTANBUL TUZLA KİMYA SANAYİCİLERİ",
      "İTOB",
      "İZMİR ALİAĞA BAĞYURDU ( ÖZEL )",
      "İZMİR ATATÜRK",
      "İZMİR BAYINDIR TDİ(SERA)",
      "İZMİR KEMALPAŞA",
      "İZMİR KINIK BİTKİSEL ÜRETİM (TOHUM,FİDE,TIBBİ VE AROMATİK BİTKİLER VB.) TDİ",
      "İZMİR PANCAR",
      "KADİRLİ",
      "KAHRAMANMARAŞ",
      "KAHRAMANMARAŞ ELBİSTAN TDİ (BESİ)",
      "KAHRAMANMARAŞ GIDA İHTİSAS",
      "KAHRAMANMARAŞ TOMSUKLU",
      "KALESERAMİK ( ÖZEL )",
      "KAMAN",
      "KANDIRA GIDA İHTİSAS",
      "KAPAKLI",
      "KARABÜK",
      "KARAMAN",
      "KARAMAN 2.",
      "KARAPINAR",
      "KARASU",
      "KARS",
      "KARS İHTİSAS BESİ",
      "KASTAMONU",
      "KASTAMONU DEVREKANİ TDİ (BESİ)",
      "KASTAMONU SEYDİLER",
      "KASTAMONU TAŞKÖPRÜ",
      "KAŞ GIDA İHTİSAS",
      "KAYAPA",
      "KAYNARCA MOBİLYA İHTİSAS",
      "KAYSERİ",
      "KAYSERİ İNCESU",
      "KAYSERİ KOCASİNAN TDİ(JEO.KAYN.SERA)",
      "KAYSERİ MİMARSİNAN",
      "KESKİN",
      "KESTEL",
      "KEŞAN",
      "KINIK",
      "KIRIKKALE I",
      "KIRIKKALE SİLAH SANAYİ İHTİSAS",
      "KIRKLARELİ",
      "KIRKLARELİ KAVAKLI TDİ (SÜT)",
      "KIRŞEHİR",
      "KİLİS",
      "KİLİS POLATELİ ŞAHİNBEY",
      "KOCAELİ ALİKAHYA",
      "KOCAELİ ARSLANBEY",
      "KOCAELİ GEBZE DİLOVASI",
      "KOCAELİ GEBZE GÜZELLER",
      "KOCAELİ GEBZE PLASTİKÇİLER",
      "KOCAELİ GEBZE V(KİMYA) İHTİSAS",
      "KOCAELİ GEBZE VI(İMES)MAKİNE İHTİSAS",
      "KOCAELİ KOBİ",
      "KONYA",
      "KONYA BİRİNCİ",
      "KONYA EREĞLİ",
      "KONYA-ILGIN",
      "KORKUTELİ",
      "KOZAN",
      "KULA DERİ İHTİSAS VE KARMA",
      "KULU",
      "KURŞUNLU ÇAVUNDUR",
      "KUYULU",
      "KÜTAHYA",
      "KÜTAHYA 30 AĞUSTOS",
      "KÜTAHYA ALTINTAŞ ZAFER",
      "KÜTAHYA SERAMİK",
      "KÜTAHYA SİMAV TDİ(JEO. KAYN. SERA)",
      "KÜTAHYA TAVŞANLI",
      "MAKİNE İHTİSAS",
      "MALATYA (BESİ) TDİ",
      "MALATYA II.",
      "MALATYA MERKEZ I.",
      "MALATYA YAZIHAN (SERA) TDİ",
      "MALKARA",
      "MANAVGAT",
      "MANİSA",
      "MANİSA TURGUTLU I.",
      "MARDİN",
      "MARDİN 2’NCİ",
      "MARDİN MİDYAT",
      "MARMARA YÜKSEK TEKNOLOJİ VE MAKİNE İHTİSAS",
      "MARMARAEREĞLİSİ",
      "MENEMEN PLASTİK İHTİSAS",
      "MERSİN ERDEMLİ",
      "MERSİN TARSUS",
      "MERSİN TARSUS TARIMSAL ÜRÜN İŞLEME İHTİSAS",
      "MİLAS",
      "MUCUR",
      "MUĞLA SEYDİKEMER TDİ (SÜT)",
      "MURADİYE",
      "MURATLI",
      "MUSTAFAKEMALPAŞA",
      "MUSTAFAKEMALPAŞA-MERMERCİLER İHTİSAS",
      "MUŞ",
      "MUT",
      "NEVŞEHİR",
      "NEVŞEHİR ACIGÖL",
      "NEVŞEHİR AVANOS KAPADOKYA",
      "NEVŞEHİR KOZAKLI TDİ (JEO.KAYN.SERA)",
      "NİĞDE",
      "NİĞDE BOR KARMA VE DERİ İHTİSAS",
      "NİĞDE II.",
      "NİĞDE-BOR TDİ (BESİ)",
      "NİKSAR",
      "NİLÜFER",
      "NİZİP",
      "NİZİP 2.",
      "NURDAĞI",
      "OLTU",
      "ORDU",
      "ORDU 2’NCİ",
      "ORDU ÜNYE",
      "ORTAKLAR",
      "OSMANCIK",
      "OSMANELİ",
      "OSMANİYE",
      "OSTİM",
      "ÖDEMİŞ",
      "ÖRENLİ",
      "PAGDER-ASLAN PLASTİKÇİLER İHTİSAS ( ÖZEL )",
      "PAKOP PLASTİK İHTİSAS",
      "PAZARYERİ",
      "POLATLI",
      "POLATLI TİCARET ODASI",
      "RİZE",
      "RİZE ARDEŞEN",
      "SAKARYA I",
      "SAKARYA II",
      "SAKARYA III",
      "SAKARYA KAYNARCA DOĞU MARMARA MAKİNE İMALATÇILARI İHTİSAS",
      "SAKARYA OTOMOTİV VE METAL İHTİSAS",
      "SAKARYA SÖĞÜTLÜ",
      "SALİHLİ",
      "SAMSUN BAFRA",
      "SAMSUN BAFRA (SERA) TDİ",
      "SAMSUN GIDA İHTİSAS",
      "SAMSUN HAVZA",
      "SAMSUN KAVAK",
      "SAMSUN MERKEZ",
      "SAMSUN YENİ",
      "SANDIKLI",
      "SARAYÖNÜ",
      "SEYDİŞEHİR",
      "SİİRT",
      "SİLİFKE",
      "SİNOP",
      "SİVAS GEMEREK",
      "SİVAS MERKEZ I",
      "SİVAS ŞARKIŞLA",
      "SİVEREK",
      "SİVRİHİSAR",
      "SOMA",
      "SÖĞÜT",
      "SÖKE",
      "SULUOVA",
      "SULUOVA BESİ",
      "SUMBAS KARMA",
      "ŞABANÖZÜ(ÇANKIRI)",
      "ŞANLIURFA",
      "ŞANLIURFA GIDA İHTİSAS",
      "ŞANLIURFA TDİ",
      "ŞANLIURFA VİRANŞEHİR",
      "ŞARKIŞLA TDİ",
      "ŞEREFLİKOÇHİSAR",
      "ŞIRNAK",
      "ŞIRNAK CİZRE",
      "ŞIRNAK SİLOPİ TDİ (SERA)",
      "ŞİNİK (AKÇAABAT)",
      "ŞUHUT",
      "TARSUS",
      "TERME KARMA",
      "TİRE",
      "TOKAT MERKEZ",
      "TOKAT-TURHAL",
      "TORBALI KARMA VE MOBİLYA",
      "TOSAB BURSA TEKSTİL BOYAHANELERİ İHTİSAS",
      "TOSB OTOMOTİV TEDARİK SANAYİ İHTİSAS",
      "TOSYA",
      "TRABZON ARSİN",
      "TRABZON BEŞİKDÜZÜ",
      "TUNCELİ",
      "TÜRKOĞLU",
      "ULUDAĞ",
      "UŞAK",
      "UŞAK BANAZ TDİ(JEO.KAYN.SERA)",
      "UŞAK DERİ (KARMA)",
      "UŞAK KARAHALLI",
      "UZUNKÖPRÜ",
      "UZUNKÖPRÜ ATATÜRK",
      "VAKFIKEBİR (TRABZON)",
      "VAN",
      "VAN TDİ (BESİ)",
      "VELİKÖY",
      "VELİMEŞE",
      "VEZİRKÖPRÜ KARMA",
      "YALIBOYU",
      "YALOVA AVRASYA GİYİM VE GEMİ İHTİSAS",
      "YALOVA ÇİFTLİKKÖY TDİ SERA (BİTKİSEL ÜRETİM)",
      "YALOVA KALIP İMALATI İHTİSAS",
      "YALOVA KOMPOZİT VE KİMYA İHTİSAS",
      "YALOVA MAKİNA İHTİSAS",
      "YENİCE",
      "YENİÇAĞA",
      "YENİŞEHİR",
      "YOZGAT",
      "ZİLE",
      "ZONGULDAK (SERA) TDİ",
      "ZONGULDAK ALAPLI",
      "ZONGULDAK ÇAYCUMA",
      "ZONGULDAK EREĞLİ",
      "ZONGULDAK GÖKÇEBEY"
    ].map(x => {
      return { label: x, value: x };
    });
  }
};
export default DropdownDatas;
