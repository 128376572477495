import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <div>
      <HeroSection className="light hero">
        <div className="heroInner">
          <span>
            <h1 className="text-xl md:text-5xl text-white">YEŞİL DÖNÜŞÜM VE SÜRDÜRÜLEBİLİRLİK MERKEZİ</h1>
            <p className="mt-4 text-sm md:text-xl text-white">
              Organize Sanayi Bölgelerine (OSB) yeşil dönüşümde rehberlik ediyor, sürdürülebilirlik alanında sağlam
              adımlarla ilerleyen bir topluluk inşa ediyoruz.
            </p>
          </span>
        </div>
      </HeroSection>
    </div>
  );
};

export default Header;

const HeroSection = styled.section`
  position: relative;
  background: linear-gradient(to bottom, rgba(10, 12, 44, 0.7) 3rem, transparent 10rem),
    url(images/landing-page/hero1.png);
  background-position: center, bottom left;
  background-size: cover, cover;
  height: fit-content;
  color: #fafafc;
  padding: 15rem 3rem 6rem;

  .heroInner {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    text-align: center; /* Center the text */
    padding: 0 1rem; /* Prevent text from sticking to the edges */
  }

  h1 {
    font-weight: bold;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6); /* Adds a shadow to the text */
  }

  p {
    line-height: 1.5;
    max-width: 1000px; /* Limit the paragraph width for better readability */
    margin-top: 1rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6); /* Adds a shadow to the text */
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Darker overlay to make text stand out more */
    z-index: 1;
  }

  @media (max-width: 768px) {
    padding: 10rem 1rem 4rem;
  }

  @media (max-width: 576px) {
    background: linear-gradient(to bottom, rgba(10, 12, 44, 0.7) 3rem, transparent), url(images/landing-page/hero1.png);
    background-position: center, bottom left;
    background-size: cover, cover;
    padding: 7.5rem 1rem 3rem;

    h1 {
      font-size: 1.8rem;
      line-height: 1.2;
    }

    p {
      font-size: 1rem;
      line-height: 1.4;
    }
  }
`;
