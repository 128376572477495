import React, { useState, FormEvent, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { VirtualScroller } from "primereact/virtualscroller";
import { useToast } from "../../utils/toast";

interface Message {
  id: string;
  sender: "user" | "bot";
  text: string;
}

interface GeminiResponse {
  candidates?: {
    content: {
      parts: {
        text: string;
      }[];
    };
  }[];
}

const SoruCevapSayfası: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(false);
  const toast = useToast();
  const scrollRef = useRef<VirtualScroller>(null);

  const sendMessage = async () => {
    const cleanedInput = inputMessage.trim();
    if (!cleanedInput) {
      setButtonIsDisabled(false);
      toast.show("Lütfen geçerli bir mesaj girin.", "error");
      return;
    }

    setButtonIsDisabled(true);
    const userMessage: Message = {
      id: Date.now().toString(),
      sender: "user",
      text: inputMessage
    };
    setInputMessage("");

    setMessages(prevMessages => [...prevMessages, userMessage]);

    try {
      const botResponse = await handleButtonClick(cleanedInput);
      const botMessage: Message = {
        id: Date.now().toString(),
        sender: "bot",
        text: botResponse
      };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error during bot response:", error);
    } finally {
      setButtonIsDisabled(false);
    }
  };

  const handleButtonClick = async (message: string) => {
    const url =
      "https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyDkUuRe_Jxit2qQuA6VwjSfbUNXOADFhFI";
    const requestBody = {
      contents: [
        {
          parts: [
            {
              text: message
            }
          ]
        }
      ]
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data: GeminiResponse = await response.json();
      if (
        data.candidates &&
        data.candidates.length > 0 &&
        data.candidates[0].content &&
        data.candidates[0].content.parts &&
        data.candidates[0].content.parts.length > 0
      ) {
        const botMessage = data.candidates[0].content.parts[0].text;
        return botMessage;
      } else {
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      toast.show(`Bir hata oluştu.`, "error");
      return "Bir hata oluştu.";
    }
  };

  const renderMessages = () => {
    return (
      <VirtualScroller
        items={messages}
        itemSize={150}
        className="mb-5"
        itemTemplate={(message: Message) => {
          return (
            // <div key={message.id} className={`flex ${message.sender === "user" ? "flex-row-reverse" : ""}`}>
            //   <Card className="my-1 border-2 border-primary-100" style={{ width: "70%", padding: "0.5rem" }}>
            //     <div style={{ wordBreak: "break-all" }}>
            //       <label className="font-semibold">{message.sender === "user" ? "Ben" : "Yapay Zeka"}:</label> <br />
            //       <span className="mt-1">{message.text}</span>
            //     </div>
            //   </Card>
            // </div>
            <div key={message.id} className={`flex ${message.sender === "user" ? "flex-row-reverse" : ""}`}>
              <div className="card my-1 border-2 border-primary-100" style={{ width: "70%", padding: "0.5rem" }}>
                <div style={{ wordBreak: "break-word", marginBottom: "0.2rem" }}>
                  <span className="font-bold text-lg">{message.sender === "user" ? "Ben" : "Yapay Zeka"}:</span> <br />
                  <span style={{ display: "block", marginTop: "0.2rem" }}>{message.text}</span>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  };

  return (
    <div className="card flex flex-column" style={{ height: "77vh" }}>
      {/* Üstteki Örnek Buton */}
      <div className="pt-0 mt-0 pl-0 ml-0">
        <Button
          label="Yeni Sohbet"
          onClick={() => {
            setMessages([]);
            setInputMessage("");
          }}
        />
      </div>

      {renderMessages()}

      {/* Giriş Alanı */}
      <form
        className="flex gap-2 align-items-center mb-0 pb-0"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <div className="flex-grow-1">
          <InputText
            id="searchWord"
            name="searchWord"
            className="w-full"
            placeholder="Mesajınızı buraya yazın"
            value={inputMessage}
            style={{ height: "60px" }}
            onChange={e => setInputMessage(e.target.value)}
            disabled={buttonIsDisabled}
          />
        </div>
        <div style={{ flexShrink: 0 }}>
          <Button
            label="Gönder"
            className="w-full"
            style={{ height: "60px" }}
            type="submit"
            disabled={buttonIsDisabled || inputMessage.trim() === ""}
          />
        </div>
      </form>
    </div>
  );
};

export default SoruCevapSayfası;
