import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/auth";

const cardData = [
  {
    imgSrc: "/images/landing-page/hesaplama.jpg",
    title: "Hesaplama",
    description: "Yeşil dönüşüm ve sürdürülebilirlik politikalarınızı planlamak için gerekli hesaplamalar.",
    path: "/app/karbonayakizi"
  },
  {
    imgSrc: "/images/landing-page/rapor.jpg",
    title: "Raporlama",
    description: "Sürdürülebilirlik, çevresel performans, iyileştirme ve malzeme-kaynak yönetimi raporları.",
    path: "/app/rapor"
  },
  {
    imgSrc: "/images/landing-page/analiz.jpg",
    title: "Analiz",
    description:
      "Yeşil dönüşüm olgunluk analizi, dijital dönüşüm endeksi ve sektör/bölge/ülke genelindeki istatistikler ve raporlar.",
    path: "/app/grafik"
  },
  {
    imgSrc: "/images/landing-page/egitim.jpg",
    title: "Eğitim",
    description: "Sürdürülebilirlik, yenilikçilik, teknoloji, kurumsal gelişim ve iklim değişikliğine uyum eğitimleri.",
    path: "/app/egitim"
  },
  {
    imgSrc: "/images/landing-page/afad.jpg",
    title: "İş Sürekliliği Planı",
    description:
      "Afet durumunda sanayi kuruluşlarının faaliyet göstermesi için AFAD ve Sanayi Bakanlığı ile geliştirdiğimiz işbirliği çalışmaları.",
    path: "/app/is-surekliligi"
  },
  {
    imgSrc: "/images/landing-page/haber.jpg",
    title: "OSBÜK Bülten",
    description:
      "Türkiye genelindeki organize sanayi bölgeleriyle ilgili güncel haberler, projeler ve sektörel gelişmeler.",
    path: "/app/bulten"
  },

  {
    title: "Stratejik plan raporu",
    description: "Paydaş beklenti ve ihtiyaç analizi (2.10.2024)",
    imgSrc: "/images/landing-page/stratejikplan.jpg",
    path: "/app/stratejik-plan-raporu"
  },

  {
    title: "Stratejik Plan Çalışması",
    description: "Stratejik Plan Çalışması.",
    imgSrc: "/images/landing-page/stratejik-plan.jpg",
    path: "/app/stratejik-plan"
  },
  {
    title: "Forum",
    description: "Forum",
    imgSrc: "/images/landing-page/forum.jpg",
    path: "/app/forum-page"
  }
];

function Homepage() {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="card">
      <div className="text-center">
        <h3>Hoş Geldiniz</h3>
        <span>{auth.getUser().firstName + " " + auth.getUser().lastName}</span>
      </div>

      <div id="features" className="py-2 px-0 lg:px-4 mt-2 mx-2 lg:mx-4">
        <div className="grid justify-content-center">
          <div className="grid">
            {cardData.map((card, index) => (
              <div className="col-12 md:col-6 lg:col-4 " key={index}>
                <div
                  style={{
                    height: "130px",
                    padding: "2px",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))"
                  }}
                >
                  <div
                    className="p-3 surface-card h-full cursor-pointer hover-card"
                    style={{ borderRadius: "8px" }}
                    onClick={() => navigate(card.path)}
                  >
                    <div className="flex align-items-center">
                      <img src={card.imgSrc} className="shadow-2 w-6rem h-6rem mr-3" />
                      <div className="flex flex-column justify-content-center">
                        <h5 className="mb-1 text-900 text-lg">{card.title}</h5>
                        {/* <span className="text-600 text-sm">{card.description}</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
